import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ArchivedStage: {
    id: 'app.components.ChangeWorkflowModal.onHoldStage',
    defaultMessage: 'On Hold',
  },
  AccurateStage: {
    id: 'app.components.ChangeWorkflowModal.AccurateStage',
    defaultMessage: 'Background Check with Accurate',
  },
  AssessmentStage: {
    id: 'app.components.ChangeWorkflowModal.AssessmentStage',
    defaultMessage: 'Assessment',
  },
  BackgroundCheckerStage: {
    id: 'app.components.ChangeWorkflowModal.BackgroundCheckerStage',
    defaultMessage: 'Background check',
  },
  changeWorkflow: {
    id: 'app.components.ChangeWorkflowModal.changeWorkflow',
    defaultMessage: 'Change Workflow',
  },
  'CheckrCanada::Stage': {
    id: 'app.components.ChangeWorkflowModal.CheckrCanada::Stage',
    defaultMessage: 'Background Check with Checkr (Canada)',
  },
  configureStageMappings: {
    id: 'app.components.ChangeWorkflowModal.configureStageMappings',
    defaultMessage: 'Configure Stage Mappings',
  },
  CustomStage: {
    id: 'app.components.ChangeWorkflowModal.CustomStage',
    defaultMessage: 'Custom',
  },
  DataCollectionStage: {
    id: 'app.components.ChangeWorkflowModal.DataStage',
    defaultMessage: 'Data',
  },
  destinationStages: {
    id: 'app.components.ChangeWorkflowModal.DataCollectionStage',
    defaultMessage: 'TARGET WORKFLOW',
  },
  destinationWorkflow: {
    id: 'app.components.ChangeWorkflowModal.destinationWorkflow',
    defaultMessage: 'Select Workflow',
  },
  destinationStageSelect: {
    id: 'app.components.ChangeWorkflowModal.destinationStageSelect',
    defaultMessage: 'Destination Stage Select for {sourceStage}',
  },
  DistributeApplicantsRuleStage: {
    id: 'app.components.ChangeWorkflowModal.DistributeApplicantsRuleStage',
    defaultMessage: 'Distribute Applicants',
  },
  DocumentSignatureStage: {
    id: 'app.components.ChangeWorkflowModal.DocumentSignatureStage',
    defaultMessage: 'Document Signing',
  },
  DocumentSigningStage: {
    id: 'app.components.ChangeWorkflowModal.DocumentSigningStage',
    defaultMessage: 'Document Signing',
  },
  EmailPdfStage: {
    id: 'app.components.ChangeWorkflowModal.EmailPdfStage',
    defaultMessage: 'Email PDF',
  },
  EventStage: {
    id: 'app.components.ChangeWorkflowModal.EventStage',
    defaultMessage: 'Event',
  },
  failure: {
    id: 'app.components.ChangeWorkflowModal.failure',
    defaultMessage: 'Workflow Change failed.',
  },
  FilterStage: {
    id: 'app.components.ChangeWorkflowModal.FilterStage',
    defaultMessage: 'Filter',
  },
  FountainDocumentSigningStage: {
    id: 'app.components.ChangeWorkflowModal.FountainDocumentSigningStage',
    defaultMessage: 'Signature',
  },
  funnelHasNoApplicants: {
    id: 'app.components.ChangeWorkflowModal.funnelHasNoApplicants',
    defaultMessage: 'Opening has no applicants. Stage mapping not required.',
  },
  HiredStage: {
    id: 'app.components.ChangeWorkflowModal.HiredStage',
    defaultMessage: 'Approved',
  },
  I9FormStage: {
    id: 'app.components.ChangeWorkflowModal.I9FormStage',
    defaultMessage: 'I9 Form',
  },
  JobSelectorStage: {
    id: 'app.components.ChangeWorkflowModal.JobSelectorStage',
    defaultMessage: 'Job Selector',
  },
  JobSwitcherStage: {
    id: 'app.components.ChangeWorkflowModal.JobSwitcherStage',
    defaultMessage: 'Job Switcher',
  },
  JumpStage: {
    id: 'app.components.ChangeWorkflowModal.JumpStage',
    defaultMessage: 'Jump',
  },
  LearningStage: {
    id: 'app.components.ChangeWorkflowModal.LearningStage',
    defaultMessage: 'Learning with lessonly.com',
  },
  MismatchLocale: {
    id: 'app.components.ChangeWorkflowModal.MismatchLocale',
    defaultMessage:
      'The language of the selected workflow does not match the language of the current workflow. Applicants may see inconsistent languages, with content in {locale}.',
  },
  MultiStage: {
    id: 'app.components.ChangeWorkflowModal.MultiStage',
    defaultMessage: 'Multi',
  },
  'NewCheckrCanada::Stage': {
    id: 'app.components.ChangeWorkflowModal.NewCheckrCanada::Stage',
    defaultMessage: 'Background Check with Checkr (New Canada)',
  },
  OnfidoStage: {
    id: 'app.components.ChangeWorkflowModal.OnfidoStage',
    defaultMessage: 'Background Check with Onfido',
  },
  PartnerStage: {
    id: 'app.components.ChangeWorkflowModal.PartnerStage',
    defaultMessage: 'Partner',
  },
  RejectedStage: {
    id: 'app.components.ChangeWorkflowModal.rejectedStage',
    defaultMessage: 'Rejected',
  },
  RuleStage: {
    id: 'app.components.ChangeWorkflowModal.RuleStage',
    defaultMessage: 'Move and Update Applicants',
  },
  runLandingActions: {
    id: 'app.components.ChangeWorkflowModal.runLandingActions',
    defaultMessage: 'Send automated Emails, SMS, and perform stage actions',
  },
  SchedulerStage: {
    id: 'app.components.ChangeWorkflowModal.SchedulerStage',
    defaultMessage: 'Scheduler',
  },
  schedulerSubstageWarning: {
    id: 'app.components.ChangeWorkflowModal.schedulerSubstageWarning',
    defaultMessage:
      'If Scheduler stage or substage in the current workflow is not mapped to another Scheduler stage or substage, any existing scheduled sessions will be cancelled.',
  },
  schedulerCancelWarning: {
    id: 'app.components.ChangeWorkflowModal.schedulerCancelWarning',
    defaultMessage:
      'Any existing booked sessions in the Scheduler stage or substage in the current workflow will be canceled.',
  },
  SchedulerV2Stage: {
    id: 'app.components.ChangeWorkflowModal.SchedulerV2Stage',
    defaultMessage: 'Scheduler',
  },
  SchoolkeepStage: {
    id: 'app.components.ChangeWorkflowModal.SchoolkeepStage',
    defaultMessage: 'Learning with Northpass',
  },
  selectStage: {
    id: 'app.components.ChangeWorkflowModal.selectStage',
    defaultMessage: 'Select stage',
  },
  selectWorkflow: {
    id: 'app.components.ChangeWorkflowModal.selectWorkflow',
    defaultMessage:
      'Select a different workflow you want to be associated with {openingName}',
  },
  sourceStages: {
    id: 'app.components.ChangeWorkflowModal.sourceStages',
    defaultMessage: 'CURRENT WORKFLOW',
  },
  SterlingStage: {
    id: 'app.components.ChangeWorkflowModal.SterlingStage',
    defaultMessage: 'Background Check with Sterling (UK)',
  },
  success: {
    id: 'app.components.ChangeWorkflowModal.success',
    defaultMessage: 'Successfully changed Workflow.',
  },
  successAsync: {
    id: 'app.components.ChangeWorkflowModal.successAsync',
    defaultMessage:
      'Workflow change is being processed.  You will receive an email when it is complete.',
  },
  TechCheckStage: {
    id: 'app.components.ChangeWorkflowModal.TechCheckStage',
    defaultMessage: 'Tech Check',
  },
  updateWorkflowBody: {
    id: 'app.components.ChangeWorkflowModal.updateWorkflowBody',
    defaultMessage:
      'The current workflow has applicants in the stages listed below. You must map each stage to a stage in your target workflow. This will ensure applicants are moved to the correct stage in your target workflow.',
  },
  unableToLoadWorkflows: {
    id: 'app.components.ChangeWorkflowModal.unableToLoadWorkflows',
    defaultMessage:
      'Something went wrong. Unable to load Workflows for account.',
  },
  unableToLoadSourceStages: {
    id: 'app.components.ChangeWorkflowModal.unableToLoadSourceStages',
    defaultMessage: 'Unable to load stages for the source workflow.',
  },
  unableToLoadDestinationStages: {
    id: 'app.components.ChangeWorkflowModal.unableToLoadDestinationStages',
    defaultMessage: 'Unable to load stages for the destination workflow.',
  },
  VideoRecordingStage: {
    id: 'app.components.ChangeWorkflowModal.VideoRecordingStage',
    defaultMessage: 'Video Recording',
  },
  W4StateFormStage: {
    id: 'app.components.ChangeWorkflowModal.W4StateFormStage',
    defaultMessage: 'W4 State Form',
  },
  W4FederalFormStage: {
    id: 'app.components.ChangeWorkflowModal.W4FederalFormStage',
    defaultMessage: 'W4 Federal Form',
  },
  workflow: {
    id: 'app.components.ChangeWorkflowModal.workflow',
    defaultMessage: 'Workflow',
  },
  WotcStage: {
    id: 'app.components.ChangeWorkflowModal.WotcStage',
    defaultMessage: 'WOTC Screening (Synergi)',
  },
});
