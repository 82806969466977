import { AgentStatus, AxAgentValues, Step } from './types';

export const AI_AGENT_SETUP = 'ai_agent_setup';
export const AI_RECOMMENDATIONS = 'ai_recommendations';

export const steps: Step[] = [
  { number: 1, label: 'Upload knowledge base' },
  { number: 2, label: 'Customize AI agent' },
  { number: 3, label: 'Customize widget appearance' },
];

export const initialAxAgentValues: AxAgentValues = {
  chatbotName: '',
  contactInfo: '',
  knowledgeBaseUuid: '',
  logoUrl: '',
  primaryColor: '',
  brandName: '',
  brandId: '',
  phoneNumberFallback: '',
  emailFallback: '',
  websiteFallback: '',
  enableInFountainPlatforms: false,
  smsEnabled: false,
  brands: [],
  atsConnectionId: '',
  axAgentId: '',
  isBrandsAvailable: false,
  sampleKnowledgeBaseFileUrl: '',
  knowledgeBaseHelpCenterArticleUrl: '',
  status: 'draft' as AgentStatus,
  totalChatAgents: 0,
  isAllBrandSetup: false,
  customWebsites: [],
};
